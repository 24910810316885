@mixin flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-wrapper {
  @include flex;
  min-height: 80%;
}

.login-logo {
  font-size: 24px;
  margin: 20px 10px;
}

.loginForm-wrapper {
  width: 400px;
}
