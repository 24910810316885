.info-part {
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;

  &.compact .ant-row {
    margin-bottom: 5px;
  }

  &.green {
    background: #b7eb8f;
  }

  &.blue {
    background: #d6e4ff;
  }
  &.light-blue {
    background: #d1e5ff;
  }

  &.yellow {
    background: #ffe58f;
  }

  &.red {
    background: #ffa39e;
  }
}

.carousel_elem {
  width: 100%;
  height: 50vh;
  background-size: contain !important;
}

.ant-carousel .slick-slide {
  height: 52vh !important;
}

.file_wrap {
  max-height: 238px;
  height: 238px;
  padding: 15px !important;
  display: flex !important;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  &.yellow {
    background: #ffe58f;
  }
}

.area_file {
  height: 238px;
  padding: 0 !important;
}

.file_elem_icon {
  width: 27px;
  height: 25px;
  display: inline-block;
   @media screen and (max-width: 576px) {
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
   }
}
.action_request {
  display: flex;
  justify-content: space-between;
  padding-left: 80px;
  @media screen and (max-width: 768px) {
    padding-left: 0px;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.action_request_wrap {
  display: flex;
  width: auto;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.action_request_title {
  margin-right: 10px;
  position: relative;
  &.required {
    &::before {
      content: '*';
      color: red;
      position: absolute;
      left: -6px;
      font-size: 14px;
    }
  }
}

.action_buttons {
  margin: 20px 0 !important;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: center;
    .ant-btn {
      width: 130px;
      margin: 0 0 20px 0 !important;
    }
  }
}
.error-message {
  margin-bottom: 15px;
  text-align: center;
  width: 100%;
  color: #f5222d;
  font-size: 16px;
}

.requestTitle {
  margin-left: 20px;
  display: block;
}

.smetaModal  {
  &.ant-modal-wrap{
    max-height: 90vh;
  }
  .ant-modal{
    width: 95vw !important;
  }
}