.requestTable .ant-table {
  .ant-table-pagination.ant-pagination {
    margin: 0;
  }
  font-size: 12px !important;
  .requestRow {
    cursor: pointer;
  }
  .hideColumn {
    display: none;
  }
  .priority {
    min-width: 17px;
    height: 40px;
    width: 100%;
    margin: auto;
    &.normal {
      background: #52c41a;
    }
    &.high {
      background: #ffa940;
    }
    &.critical {
      background: #ff4d4f;
    }
  }
  .ant-table-row-cell-ellipsis {
    padding: 8px 4px !important;
    text-align: center;
    &.priority {
      padding: 0 !important;
    }
  }
}
.requestTable {
  .ant-table-pagination.ant-pagination {
    margin: 5px 0;
  }
}
